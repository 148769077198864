import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';


import { DonHang } from 'src/app/shared/DonHang.model';
import { DonHangService } from 'src/app/shared/DonHang.service';
import { DonHangDetailComponent } from '../don-hang-detail/don-hang-detail.component';

import { ThanhVien } from 'src/app/shared/ThanhVien.model';
import { ThanhVienService } from 'src/app/shared/ThanhVien.service';
import { ThanhVienDetailComponent } from '../thanh-vien-detail/thanh-vien-detail.component';

@Component({
  selector: 'app-don-hang',
  templateUrl: './don-hang.component.html',
  styleUrls: ['./don-hang.component.css']
})
export class DonHangComponent implements OnInit {

  @ViewChild('DonHangSort') DonHangSort: MatSort;
  @ViewChild('DonHangPaginator') DonHangPaginator: MatPaginator;

  constructor(
    private dialog: MatDialog,
    public NotificationService: NotificationService,
    public DownloadService: DownloadService,

    public DonHangService: DonHangService,
    public ThanhVienService: ThanhVienService,


  ) { }

  ngOnInit(): void {
    this.ThanhVienService.GetLogin();
    this.DonHangSearch();
  }

  DonHangSearch() {
    this.DonHangService.IsShowLoading = true;
    if (this.DonHangService.BaseParameter.SearchString.length > 0) {
      this.DonHangService.BaseParameter.SearchString = this.DonHangService.BaseParameter.SearchString.trim();
      if (this.DonHangService.DataSource) {
        this.DonHangService.DataSource.filter = this.DonHangService.BaseParameter.SearchString.toLowerCase();
      }
    }
    else {
      this.DonHangService.BaseParameter.ParentID = this.ThanhVienService.FormDataLogin.ID;
      this.DonHangService.GetByParentIDToListAsync().subscribe(
        res => {
          this.DonHangService.List = (res as DonHang[]).sort((a, b) => (a.NgayGhiNhan < b.NgayGhiNhan ? 1 : -1));
          this.DonHangService.DataSource = new MatTableDataSource(this.DonHangService.List);
          this.DonHangService.DataSource.sort = this.DonHangSort;
          this.DonHangService.DataSource.paginator = this.DonHangPaginator;
          this.DonHangService.IsShowLoading = false;
        },
        err => {
          this.DonHangService.IsShowLoading = false;
        }
      );
    }
  }
  DonHangDelete(element: DonHang) {
    this.DonHangService.BaseParameter.ID = element.ID;
    this.NotificationService.warn(this.DonHangService.ComponentDeleteAllNotEmpty(this.DonHangSort, this.DonHangPaginator));
  }
  DonHangAdd(ID: number) {
    this.DonHangService.BaseParameter.ID = ID;
    this.DonHangService.GetByIDAsync().subscribe(
      res => {
        this.DonHangService.FormData = res as DonHang;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(DonHangDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
          this.DonHangSearch();
        });
      },
      err => {
      }
    );
  }
  ThanhVienAdd(ID: number) {
    this.ThanhVienService.BaseParameter.ID = ID;
    this.ThanhVienService.GetByIDAsync().subscribe(
      res => {
        this.ThanhVienService.FormData = res as ThanhVien;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(ThanhVienDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
        });
      },
      err => {
      }
    );
  }
}