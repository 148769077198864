<div class="box-body">
    <ul class="nav nav-tabs justify-content-end" role="tablist">
        <li class="nav-item"> <a class="nav-link active" data-bs-toggle="tab" href="#ThanhVienBlock" role="tab"><span
                    class="hidden-sm-up"><i class="ion-home"></i></span> <span class="hidden-xs-down">Block
                    [{{ThanhVienBlockService.FormData.ID}}]</span></a>
        </li>
        <li class="nav-item"> <a class="nav-link" data-bs-toggle="tab" href="#ThanhVienBlockTransation" role="tab"><span
                    class="hidden-sm-up"><i class="ion-person"></i></span> <span
                    class="hidden-xs-down">Transaction</span></a> </li>
        <li class="nav-item">
            <a class="nav-link bg-danger" data-bs-toggle="tab" role="tab" style="cursor: pointer; color: #ffffff;"
                (click)="Close()"><i class="fa fa-window-close"></i></a>
        </li>
    </ul>
    <div class="tab-content tabcontent-border">
        <div class="tab-pane active" id="ThanhVienBlock" role="tabpanel">
            <div class="row gx-4" *ngIf="ThanhVienBlockService.FormData.ID==0">
                <div class="col-lg-6 col-sm-12 col-12">
                    <a style="width: 100%;" title="Save changes" (click)="ThanhVienBlockSave()"
                        class="btn btn-warning"><i class="fa fa-save"></i> Save changes</a>
                </div>
            </div>
            <div class="row gx-4">
                <div class="col-lg-6 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Token</label>
                        <input name="Code" [(ngModel)]="ThanhVienBlockService.FormData.Code" placeholder="Token"
                            type="text" class="form-control" disabled>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Date created</label>
                        <input [ngModel]="ThanhVienBlockService.FormData.NgayGhiNhan | date:'yyyy-MM-dd HH:mm:ss'"
                            style="text-align: right;" (ngModelChange)="DateNgayGhiNhan($event)"
                            [ngModelOptions]="{ updateOn: 'blur' }" type="datetime" class="form-control"
                            autocomplete="off" disabled>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Sender</label>
                        <input name="DiaChiChuyen" [(ngModel)]="ThanhVienBlockService.FormData.DiaChiChuyen"
                            placeholder="Sender" type="text" class="form-control" disabled>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Send To</label>
                        <input name="DiaChiNhan" [(ngModel)]="ThanhVienBlockService.FormData.DiaChiNhan"
                            placeholder="Send To" type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Zone</label>
                        <input name="KhuVuc" [(ngModel)]="ThanhVienBlockService.FormData.KhuVuc" placeholder="Zone"
                            type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Note</label>
                        <input name="Note" [(ngModel)]="ThanhVienBlockService.FormData.Note" placeholder="Note"
                            type="text" class="form-control">
                    </div>
                </div>
                <div class="col-lg-6 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Type</label>
                        <input name="TypeName" [(ngModel)]="ThanhVienBlockService.FormData.TypeName" placeholder="Type"
                            type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Function</label>
                        <input name="Description" [(ngModel)]="ThanhVienBlockService.FormData.Description"
                            placeholder="Function" type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Total</label>
                        <input name="ThanhTienSauCung" [(ngModel)]="ThanhVienBlockService.FormData.ThanhTienSauCung"
                            placeholder="Total" type="number" class="form-control" style="text-align: right;">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Transaction</label>
                        <input name="SoLuongTransation" [(ngModel)]="ThanhVienBlockService.FormData.SoLuongTransation"
                            placeholder="Transaction" type="number" class="form-control" style="text-align: right;">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Gas</label>
                        <input name="SoLuongGas" [(ngModel)]="ThanhVienBlockService.FormData.SoLuongGas"
                            placeholder="Gas" type="number" class="form-control" style="text-align: right;">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Month</label>
                        <input name="SoLuongThang" [(ngModel)]="ThanhVienBlockService.FormData.SoLuongThang"
                            placeholder="Month" type="number" class="form-control" style="text-align: right;">
                    </div>
                </div>
            </div>
        </div>
        <div class="tab-pane" id="ThanhVienBlockTransation" role="tabpanel">
            <div class="box-body">
                <div class="row gx-4">
                    <div class="col-lg-11 col-sm-12 col-12">
                        <input [(ngModel)]="ThanhVienBlockTransationService.BaseParameter.SearchString"
                            placeholder="Search ..." class="form-control form-control-lg"
                            (keyup.enter)="ThanhVienBlockTransationSearch()">
                    </div>
                    <div class="col-lg-1 col-sm-6 col-12">
                        <a style="width: 100%;" class="btn btn-success" title="Search"
                            (click)="ThanhVienBlockTransationSearch()"><i class="fa fa-search"></i></a>
                    </div>
                </div>
                <div class="table-responsive">
                    <table mat-table matSort #ThanhVienBlockTransationSort="matSort"
                        [dataSource]="ThanhVienBlockTransationService.DataSource" class="table table-striped m-0">
                        <ng-container matColumnDef="STT">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                No
                            </th>
                            <td mat-cell *matCellDef="let element; index as i;" style="text-align: center;">
                                <div *ngIf="ThanhVienBlockTransationPaginator">
                                    {{ThanhVienBlockTransationPaginator.pageSize *
                                    ThanhVienBlockTransationPaginator.pageIndex + i + 1}}
                                </div>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="ID">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>ID
                            </th>
                            <td mat-cell *matCellDef="let element">
                                {{element.ID}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="NgayGhiNhan">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Date created
                            </th>
                            <td mat-cell *matCellDef="let element">
                                {{element.NgayGhiNhan |
                                date:'dd/MM/yyyy HH:mm:ss'}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="ParentName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Block
                            </th>
                            <td mat-cell *matCellDef="let element">
                                {{element.ParentName}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="Code">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Token
                            </th>
                            <td mat-cell *matCellDef="let element">
                                <a style="cursor: pointer;" title="{{element.Code}}"
                                    class="link-primary"><b>{{element.Code}}</b></a>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="KhuVuc">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Zone</th>
                            <td mat-cell *matCellDef="let element">
                                {{element.KhuVuc}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="TypeName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
                            <td mat-cell *matCellDef="let element">
                                {{element.TypeName}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="Description">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Function</th>
                            <td mat-cell *matCellDef="let element">
                                {{element.Description}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="DiaChiChuyen">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Sender</th>
                            <td mat-cell *matCellDef="let element">
                                {{element.DiaChiChuyen}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="DiaChiNhan">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Send To
                            </th>
                            <td mat-cell *matCellDef="let element">
                                {{element.DiaChiNhan}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="SoLuongTransation">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Transaction
                            </th>
                            <td mat-cell *matCellDef="let element">
                                {{element.SoLuongTransation |
                                number:'1.0-4'}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="SoLuongGas">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Gas
                            </th>
                            <td mat-cell *matCellDef="let element">
                                {{element.SoLuongGas |
                                number:'1.0-4'}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="SoLuongThang">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Month
                            </th>
                            <td mat-cell *matCellDef="let element">
                                {{element.SoLuongThang |
                                number:'1.0-4'}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="ThanhTienSauCung">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Total
                            </th>
                            <td mat-cell *matCellDef="let element">
                                {{element.ThanhTienSauCung |
                                number:'1.0-4'}}
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="ThanhVienBlockTransationService.DisplayColumns001">
                        </tr>
                        <tr mat-row *matRowDef="let row; columns: ThanhVienBlockTransationService.DisplayColumns001;">
                        </tr>
                    </table>
                    <mat-paginator #ThanhVienBlockTransationPaginator="matPaginator" [pageSizeOptions]="[10,20,50,100]"
                        [pageSize]="6" [showFirstLastButtons]></mat-paginator>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="ThanhVienBlockService.IsShowLoading"></app-loading>