import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ThanhVienBlock } from 'src/app/shared/ThanhVienBlock.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class ThanhVienBlockService extends BaseService{

    DisplayColumns001: string[] = ['STT', 'ID', 'NgayGhiNhan', 'Code', 'TypeName', 'Description', 'DiaChiChuyen', 'DiaChiNhan'];
        
    List: ThanhVienBlock[] | undefined;
    ListFilter: ThanhVienBlock[] | undefined;
    FormData!: ThanhVienBlock;


    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.Controller = "ThanhVienBlock";
    }

    GetByDonHangIDToListAsync() {
        let url = this.APIURL + this.Controller + '/GetByDonHangIDToListAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.BaseParameter));
        return this.httpClient.post(url, formUpload, { headers: this.Headers });
    }
    GetByParentID_BatDau_KetThucToListAsync() {
        let url = this.APIURL + this.Controller + '/GetByParentID_BatDau_KetThucToListAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.BaseParameter));
        return this.httpClient.post(url, formUpload, { headers: this.Headers });
    }
    ExportByParentID_BatDau_KetThucToHTMLAsync() {
        let url = this.APIURL + this.Controller + '/ExportByParentID_BatDau_KetThucToHTMLAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.BaseParameter));
        return this.httpClient.post(url, formUpload, { headers: this.Headers });
    }
}

