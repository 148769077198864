import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ThanhVienBlockTransation } from 'src/app/shared/ThanhVienBlockTransation.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class ThanhVienBlockTransationService extends BaseService {

    DisplayColumns001: string[] = ['STT', 'ID', 'NgayGhiNhan', 'ParentName', 'Code', 'KhuVuc', 'TypeName', 'Description', 'DiaChiChuyen', 'DiaChiNhan', 'SoLuongTransation', 'SoLuongGas', 'SoLuongThang', 'ThanhTienSauCung'];
        
    List: ThanhVienBlockTransation[] | undefined;
    ListFilter: ThanhVienBlockTransation[] | undefined;
    FormData!: ThanhVienBlockTransation;

    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.Controller = "ThanhVienBlockTransation";
    }

    GetByThanhVienIDToListAsync() {
        let url = this.APIURL + this.Controller + '/GetByThanhVienIDToListAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.BaseParameter));
        return this.httpClient.post(url, formUpload, { headers: this.Headers });
    }
    GetByThanhVienID_BatDau_KetThucToListAsync() {
        let url = this.APIURL + this.Controller + '/GetByThanhVienID_BatDau_KetThucToListAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.BaseParameter));
        return this.httpClient.post(url, formUpload, { headers: this.Headers });
    }
    ExportByThanhVienID_BatDau_KetThucToHTMLAsync() {
        let url = this.APIURL + this.Controller + '/ExportByThanhVienID_BatDau_KetThucToHTMLAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.BaseParameter));
        return this.httpClient.post(url, formUpload, { headers: this.Headers });
    }
}

