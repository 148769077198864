<section class="content">
    <div class="row">
        <div class="col-lg-12 col-sm-12 col-12">
            <div class="box">
                <div class="box-header with-border">
                    <h4 class="box-title">Block</h4>
                </div>
                <div class="box-body">
                    <div class="row gx-4">                  
                        <div class="col-lg-2 col-sm-12 col-12">
                            <label *ngIf="!NotificationService.IsMobile" class="form-label">Begin</label>
                            <input [ngModel]="ThanhVienBlockService.BaseParameter.BatDau | date:'yyyy-MM-dd'"
                                style="text-align: right;" (ngModelChange)="DateBatDau($event)"
                                [ngModelOptions]="{ updateOn: 'blur' }" type="date" class="form-control form-control-lg"
                                autocomplete="off">
                        </div>
                        <div class="col-lg-2 col-sm-12 col-12">
                            <label *ngIf="!NotificationService.IsMobile" class="form-label">End</label>
                            <input [ngModel]="ThanhVienBlockService.BaseParameter.KetThuc | date:'yyyy-MM-dd'"
                                style="text-align: right;" (ngModelChange)="DateKetThuc($event)"
                                [ngModelOptions]="{ updateOn: 'blur' }" type="date" class="form-control form-control-lg"
                                autocomplete="off">
                        </div>                
                        <div class="col-lg-5 col-sm-12 col-12">       
                            <label *ngIf="!NotificationService.IsMobile" class="form-label">.</label>                                 
                            <input [(ngModel)]="ThanhVienBlockService.BaseParameter.SearchString"
                                placeholder="Search ..." class="form-control form-control-lg" (keyup.enter)="ThanhVienBlockSearch()">
                        </div>
                        <div class="col-lg-1 col-sm-12 col-12">  
                            <label *ngIf="!NotificationService.IsMobile" class="form-label">.</label>                                      
                            <a style="width: 100%;" class="btn btn-success" title="Search"
                                (click)="ThanhVienBlockSearch()"><i class="fa fa-search"></i></a>&nbsp;&nbsp;&nbsp;
                        </div>      
                        <div class="col-lg-1 col-sm-12 col-12">
                            <label *ngIf="!NotificationService.IsMobile" class="form-label">.</label>
                            <a style="width: 100%;" class="btn btn-success" title="Add"
                                (click)="ThanhVienBlockAdd(0)"><i class="fa fa-plus"></i></a>
                        </div>    
                        <div class="col-lg-1 col-sm-12 col-12">
                            <label *ngIf="!NotificationService.IsMobile" class="form-label">.</label>
                            <a style="width: 100%;" class="btn btn-success" title="Download"
                                (click)="ThanhVienBlockDownload()"><i class="fa fa-cloud-download"></i></a>
                        </div>                           
                    </div>
                    <div class="table-responsive">
                        <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                            #ThanhVienBlockSort="matSort" [dataSource]="ThanhVienBlockService.DataSource"
                            class="table table-striped m-0">
                            <ng-container matColumnDef="STT">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    No
                                </th>
                                <td mat-cell *matCellDef="let element; index as i;"
                                    style="text-align: center;">
                                    <div *ngIf="ThanhVienBlockPaginator">
                                        {{ThanhVienBlockPaginator.pageSize *
                                        ThanhVienBlockPaginator.pageIndex + i + 1}}
                                    </div>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="ID">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>ID
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.ID}}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="NgayGhiNhan">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Date created
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.NgayGhiNhan |
                                        date:'dd/MM/yyyy HH:mm:ss'}}
                                </td>
                            </ng-container>                                                                             
                            <ng-container matColumnDef="Code">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Block
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <a style="cursor: pointer;" title="{{element.Code}}"
                                        class="link-primary"
                                        (click)="ThanhVienBlockAdd(element.ID)"><b>{{element.Code}}</b></a>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="TypeName">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.TypeName}}
                                </td>
                            </ng-container>      
                            <ng-container matColumnDef="Description">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Function</th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.Description}}
                                </td>
                            </ng-container>      
                            <ng-container matColumnDef="DiaChiChuyen">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Sender</th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.DiaChiChuyen}}
                                </td>
                            </ng-container>                                        
                            <ng-container matColumnDef="DiaChiNhan">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Send To
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.DiaChiNhan}}
                                </td>
                            </ng-container>                                                                                                                                                                                                        
                            <tr mat-header-row
                                *matHeaderRowDef="ThanhVienBlockService.DisplayColumns001">
                            </tr>
                            <tr mat-row
                                *matRowDef="let row; columns: ThanhVienBlockService.DisplayColumns001;">
                            </tr>
                        </table>
                        <mat-paginator *ngIf="!NotificationService.IsMobile"
                            #ThanhVienBlockPaginator="matPaginator" [pageSizeOptions]="[10,20,50,100]"
                            [pageSize]="6" [showFirstLastButtons]></mat-paginator>
                        <table *ngIf="NotificationService.IsMobile" mat-table matSort
                            #ThanhVienBlockSort="matSort" [dataSource]="ThanhVienBlockService.DataSource"
                            class="table table-striped m-0">
                            <ng-container matColumnDef="STT">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    List
                                </th>
                                <td mat-cell *matCellDef="let element; index as i;">
                                    <div class="row gx-4">
                                        <div class="col-lg-12 col-sm-12 col-12">                                                                    
                                            <a class="form-label" style="cursor: pointer;"
                                                title="{{element.Name}}" class="link-primary"
                                                (click)="ThanhVienBlockAdd(element.ID)"><b>{{element.Code}}</b></a>
                                        </div>                                                                                                                                                                                               
                                    </div>
                                </td>
                            </ng-container>
                            <tr mat-header-row
                                *matHeaderRowDef="ThanhVienBlockService.DisplayColumnsMobile">
                            </tr>
                            <tr mat-row
                                *matRowDef="let row; columns: ThanhVienBlockService.DisplayColumnsMobile;">
                            </tr>
                        </table>
                        <mat-paginator *ngIf="NotificationService.IsMobile"
                            #ThanhVienBlockPaginator="matPaginator" [pageSizeOptions]="[10,20,50,100]"
                            [pageSize]="10000" [showFirstLastButtons]></mat-paginator>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<app-loading *ngIf="ThanhVienBlockService.IsShowLoading"></app-loading>