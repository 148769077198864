<section class="content">
    <div class="row">
        <div class="col-lg-12 col-sm-12 col-12">
            <div class="box">
                <div class="box-header with-border">
                    <h4 class="box-title">Transaction</h4>
                </div>
                <div class="box-body">
                    <div class="row gx-4">           
                        <div class="col-lg-3 col-sm-12 col-12">
                            <label *ngIf="!NotificationService.IsMobile" class="form-label">Begin</label>
                            <input [ngModel]="ThanhVienBlockTransationService.BaseParameter.BatDau | date:'yyyy-MM-dd'"
                                style="text-align: right;" (ngModelChange)="DateBatDau($event)"
                                [ngModelOptions]="{ updateOn: 'blur' }" type="date" class="form-control form-control-lg"
                                autocomplete="off">
                        </div>
                        <div class="col-lg-3 col-sm-12 col-12">
                            <label *ngIf="!NotificationService.IsMobile" class="form-label">End</label>
                            <input [ngModel]="ThanhVienBlockTransationService.BaseParameter.KetThuc | date:'yyyy-MM-dd'"
                                style="text-align: right;" (ngModelChange)="DateKetThuc($event)"
                                [ngModelOptions]="{ updateOn: 'blur' }" type="date" class="form-control form-control-lg"
                                autocomplete="off">
                        </div>                                  
                        <div class="col-lg-4 col-sm-12 col-12">  
                            <label *ngIf="!NotificationService.IsMobile" class="form-label">.</label>                                     
                            <input [(ngModel)]="ThanhVienBlockTransationService.BaseParameter.SearchString"
                                placeholder="Search ..." class="form-control form-control-lg" (keyup.enter)="ThanhVienBlockTransationSearch()">
                        </div>
                        <div class="col-lg-1 col-sm-12 col-12">                                      
                            <label *ngIf="!NotificationService.IsMobile" class="form-label">.</label>  
                            <a style="width: 100%;" class="btn btn-success" title="Search"
                                (click)="ThanhVienBlockTransationSearch()"><i class="fa fa-search"></i></a>&nbsp;&nbsp;&nbsp;
                        </div>       
                        <div class="col-lg-1 col-sm-12 col-12">
                            <label *ngIf="!NotificationService.IsMobile" class="form-label">.</label>
                            <a style="width: 100%;" class="btn btn-success" title="Download"
                                (click)="ThanhVienBlockTransationDownload()"><i class="fa fa-cloud-download"></i></a>
                        </div>                                
                    </div>
                    <div class="table-responsive">
                        <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                            #ThanhVienBlockTransationSort="matSort"
                            [dataSource]="ThanhVienBlockTransationService.DataSource"
                            class="table table-striped m-0">
                            <ng-container matColumnDef="STT">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    No
                                </th>
                                <td mat-cell *matCellDef="let element; index as i;"
                                    style="text-align: center;">
                                    <div *ngIf="ThanhVienBlockTransationPaginator">
                                        {{ThanhVienBlockTransationPaginator.pageSize *
                                        ThanhVienBlockTransationPaginator.pageIndex + i + 1}}
                                    </div>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="ID">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>ID
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.ID}}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="NgayGhiNhan">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Date created
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.NgayGhiNhan |
                                        date:'dd/MM/yyyy HH:mm:ss'}}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="ParentName">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Block
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <a style="cursor: pointer;" title="{{element.ParentName}}"
                                        class="link-primary"
                                        (click)="ThanhVienBlockAdd(element.ParentID)"><b>{{element.ParentName}}</b></a>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="Code">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Token
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <a style="cursor: pointer;" title="{{element.Code}}"
                                        class="link-primary" (click)="ThanhVienBlockTransationAdd(element.ID)"><b>{{element.Code}}</b></a>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="KhuVuc">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Zone</th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.KhuVuc}}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="TypeName">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.TypeName}}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="Description">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Function</th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.Description}}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="DiaChiChuyen">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Sender
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.DiaChiChuyen}}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="DiaChiNhan">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Send To
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.DiaChiNhan}}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="SoLuongTransation">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Transaction
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.SoLuongTransation |
                                        number:'1.0-4'}}
                                </td>
                            </ng-container>       
                            <ng-container matColumnDef="SoLuongGas">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Gas
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.SoLuongGas |
                                        number:'1.0-4'}}
                                </td>
                            </ng-container>    
                            <ng-container matColumnDef="SoLuongThang">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Month
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.SoLuongThang |
                                        number:'1.0-4'}}
                                </td>
                            </ng-container>       
                            <ng-container matColumnDef="ThanhTienSauCung">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Total
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.ThanhTienSauCung |
                                    number:'1.0-4'}}
                                </td>
                            </ng-container>                                          
                            <tr mat-header-row
                                *matHeaderRowDef="ThanhVienBlockTransationService.DisplayColumns001">
                            </tr>
                            <tr mat-row
                                *matRowDef="let row; columns: ThanhVienBlockTransationService.DisplayColumns001;">
                            </tr>
                        </table>
                        <mat-paginator *ngIf="!NotificationService.IsMobile"
                            #ThanhVienBlockTransationPaginator="matPaginator"
                            [pageSizeOptions]="[10,20,50,100]" [pageSize]="6"
                            [showFirstLastButtons]></mat-paginator>
                        <table *ngIf="NotificationService.IsMobile" mat-table matSort
                            #ThanhVienBlockTransationSort="matSort"
                            [dataSource]="ThanhVienBlockTransationService.DataSource"
                            class="table table-striped m-0">
                            <ng-container matColumnDef="STT">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    Danh sách
                                </th>
                                <td mat-cell *matCellDef="let element; index as i;">                                                            
                                </td>
                            </ng-container>
                            <tr mat-header-row
                                *matHeaderRowDef="ThanhVienBlockTransationService.DisplayColumnsMobile">
                            </tr>
                            <tr mat-row
                                *matRowDef="let row; columns: ThanhVienBlockTransationService.DisplayColumnsMobile;">
                            </tr>
                        </table>
                        <mat-paginator *ngIf="NotificationService.IsMobile"
                            #ThanhVienBlockTransationPaginator="matPaginator"
                            [pageSizeOptions]="[10,20,50,100]" [pageSize]="10000"
                            [showFirstLastButtons]></mat-paginator>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<app-loading *ngIf="ThanhVienBlockTransationService.IsShowLoading"></app-loading>
