<section class="content">
    <div class="row">
        <div class="col-lg-3 col-sm-12 col-12">
        </div>
        <div class="col-lg-6 col-sm-12 col-12">
            <div class="box">
                <div class="box-body">
                    <div class="row">
                        <div class="col-lg-12 col-sm-12 col-12" style="text-align: center;">
                            <img width="100" height="100" src="assets/image/logo.png"
                                title="The Enterprise Blockchain Platform" alt="The Enterprise Blockchain Platform">
                        </div>
                        <div class="col-lg-12 col-sm-12 col-12" style="text-align: center;">
                            <h4 class="box-title text-info mb-0">{{PageTitle}}</h4>
                        </div>
                        <div class="col-lg-12 col-sm-12 col-12" style="text-align: center;">
                            <h4 class="box-title text-info mb-0">Authentication</h4>
                        </div>
                        <div class="col-lg-12 col-sm-12 col-12">
                            <label class="form-label" for="TaiKhoan">Account<span class="text-danger">*</span></label>
                            <div class="input-group mb-3">
                                <span class="input-group-text bg-transparent"><i class="ti-user"></i></span>
                                <input name="TaiKhoan" [(ngModel)]="ThanhVienService.FormData.TaiKhoan"
                                    placeholder="Account" type="text" class="form-control" autofocus>
                            </div>
                        </div>
                        <div class="col-lg-12 col-sm-12 col-12">
                            <label class="form-label" for="password">Password <span class="text-danger">*</span></label>
                            <div class="input-group mb-3">
                                <span class="input-group-text  bg-transparent" (click)="MatKhauChangeType()"
                                    style="cursor: pointer;"><i class="ti-lock"></i></span>
                                <input name="password" id="password" [(ngModel)]="ThanhVienService.FormData.MatKhau"
                                    placeholder="Password" [type]="MatKhauIsActive ? 'password' : 'text'"
                                    class="form-control" (keyup.enter)="Login()">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="box-footer">
                    <button type="button" class="btn btn-warning me-1" style="width: 100%;" (click)="Login()"
                        [disabled]="!ThanhVienService.FormData.TaiKhoan || !ThanhVienService.FormData.MatKhau">Authentication</button>
                </div>
            </div>
        </div>
        <div class="col-lg-3 col-sm-12 col-12">
        </div>
    </div>
</section>
<app-loading *ngIf="ThanhVienService.IsShowLoading"></app-loading>