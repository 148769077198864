<section class="content">
    <div class="row">
        <div class="col-lg-12 col-sm-12 col-12">
            <div class="box">
                <div class="box-header with-border">
                    <h4 class="box-title">Account information</h4>
                </div>
                <div class="box-body">
                    <div class="row gx-4">
                        <div class="col-lg-4 col-sm-12 col-12">
                            <a style="width: 100%;" title="Save changes" (click)="ThanhVienSave()"
                                class="btn btn-info"><i class="fa fa-save"></i> Save changes</a>
                        </div>
                        <div class="col-lg-4 col-sm-12 col-12">
                            <a style="width: 100%;" title="Refresh Key Public" (click)="ThanhVienRefreshKeyPublic()"
                                class="btn btn-info"><i class="fa fa-save"></i> Refresh Key public</a>
                        </div>
                        <div class="col-lg-2 col-sm-12 col-12">
                            <div class="checkbox checkbox-success">
                                <input type="checkbox" id="Active"
                                    [(ngModel)]="ThanhVienService.FormDataLogin.Active" disabled />
                                <label for="Active"> Active </label>
                            </div>
                        </div>
                    </div>
                    <div class="row gx-4">
                        <div class="col-lg-4 col-sm-12 col-12">
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Account</label>
                                <input name="TaiKhoan" [(ngModel)]="ThanhVienService.FormDataLogin.TaiKhoan"
                                    [disabled]="ThanhVienService.FormDataLogin && ThanhVienService.FormDataLogin.ID>0"
                                    placeholder="Account" type="text" class="form-control">
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Password</label>
                                <input name="MatKhau" [(ngModel)]="ThanhVienService.FormDataLogin.MatKhau"
                                    placeholder="Password" type="password" class="form-control">
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Sender</label>
                                <input name="DiaChiVi" [(ngModel)]="ThanhVienService.FormDataLogin.DiaChiVi"
                                    placeholder="Sender" type="text" disabled class="form-control" disabled>
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Avatar: </label>
                                <br />
                                <input type="file" (change)="ChangeFileName($event.target.files)">
                                <br />
                                <img src="{{ThanhVienService.FormDataLogin.FileName}}" class="img-fluid">
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-12 col-12">
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Name</label>
                                <input name="Name" [(ngModel)]="ThanhVienService.FormDataLogin.Name" placeholder="Name"
                                    type="text" class="form-control">
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Tax code</label>
                                <input name="MaSoThue" [(ngModel)]="ThanhVienService.FormDataLogin.MaSoThue"
                                    placeholder="Tax code" type="text" class="form-control">
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">ID number</label>
                                <input name="CCCD" [(ngModel)]="ThanhVienService.FormDataLogin.CCCD"
                                    placeholder="ID number" type="text" class="form-control">
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Phone</label>
                                <input name="DienThoai" [(ngModel)]="ThanhVienService.FormDataLogin.DienThoai"
                                    placeholder="Phone" type="text" class="form-control">
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Email</label>
                                <input name="Email" [(ngModel)]="ThanhVienService.FormDataLogin.Email"
                                    placeholder="Email" type="text" class="form-control">
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Address</label>
                                <input name="DiaChi" [(ngModel)]="ThanhVienService.FormDataLogin.DiaChi"
                                    placeholder="Address" type="text" class="form-control">
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Note</label>
                                <input name="Note" [(ngModel)]="ThanhVienService.FormDataLogin.Note" placeholder="Note"
                                    type="text" class="form-control">
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-12 col-12">
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Transation</label>
                                <input name="Transation" [(ngModel)]="ThanhVienService.FormDataLogin.SoLuongTransation"
                                    placeholder="Transation" type="number" disabled class="form-control"
                                    style="text-align: right;">
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Consumed</label>
                                <input name="SoLuongTransationDaSuDung"
                                    [(ngModel)]="ThanhVienService.FormDataLogin.SoLuongTransationDaSuDung"
                                    placeholder="Consumed" type="number" disabled class="form-control"
                                    style="text-align: right;">
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Availability</label>
                                <input name="SoLuongTransationConLai"
                                    [(ngModel)]="ThanhVienService.FormDataLogin.SoLuongTransationConLai"
                                    placeholder="Availability" type="number" disabled class="form-control"
                                    style="text-align: right;">
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Gas</label>
                                <input name="SoLuongGas" [(ngModel)]="ThanhVienService.FormDataLogin.SoLuongGas"
                                    placeholder="Gas" type="number" disabled class="form-control"
                                    style="text-align: right;">
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Consumed</label>
                                <input name="SoLuongGasDaSuDung"
                                    [(ngModel)]="ThanhVienService.FormDataLogin.SoLuongGasDaSuDung"
                                    placeholder="Consumed" type="number" disabled class="form-control"
                                    style="text-align: right;">
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Availability</label>
                                <input name="SoLuongGasConLai"
                                    [(ngModel)]="ThanhVienService.FormDataLogin.SoLuongGasConLai"
                                    placeholder="Availability" type="number" disabled class="form-control"
                                    style="text-align: right;">
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Expiration date</label>
                                <input [ngModel]="ThanhVienService.FormDataLogin.NgayHetHan | date:'yyyy-MM-dd'"
                                    style="text-align: right;" (ngModelChange)="DateNgayHetHan($event)"
                                    [ngModelOptions]="{ updateOn: 'blur' }" type="date" class="form-control"
                                    autocomplete="off" disabled>
                            </div>
                            <div class="col-lg-12 col-sm-12 col-12">
                                <label class="form-label">Key public</label>
                                <textarea name="KeyPublic" [(ngModel)]="ThanhVienService.FormDataLogin.KeyPublic"
                                    placeholder="Public key" type="text" disabled class="form-control" rows="8"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<app-loading *ngIf="ThanhVienService.IsShowLoading"></app-loading>