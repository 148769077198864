import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DonHang } from 'src/app/shared/DonHang.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class DonHangService extends BaseService{

    DisplayColumns001: string[] = ['STT', 'ID', 'NgayGhiNhan', 'Name', 'NguoiMuaName', 'Active', 'IsThanhToan', 'IsHoanThanh', 'Save'];
    DisplayColumns002: string[] = ['STT', 'ID', 'NgayGhiNhan', 'Name', 'NguoiMuaName', 'Active', 'IsThanhToan', 'IsHoanThanh'];
        
    List: DonHang[] | undefined;
    ListFilter: DonHang[] | undefined;
    FormData!: DonHang;
    FormDataLogin!: DonHang;

    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.Controller = "DonHang";
    }
}

