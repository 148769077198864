<section class="content">
    <div class="row">
        <div class="col-lg-12 col-sm-12 col-12">
            <div class="box">
                <div class="box-header with-border">
                    <h4 class="box-title">Service order</h4>
                </div>
                <div class="box-body">
                    <div class="row">
                        <div class="col-lg-10 col-sm-12 col-12">
                            <input [(ngModel)]="DonHangService.BaseParameter.SearchString" placeholder="Search ..."
                                class="form-control form-control-lg" (keyup.enter)="DonHangSearch()">
                        </div>
                        <div class="col-lg-1 col-sm-12 col-12">
                            <a style="width: 100%;" class="btn btn-success" title="Search" (click)="DonHangSearch()"><i
                                    class="fa fa-search"></i></a>&nbsp;&nbsp;&nbsp;
                        </div>
                        <div class="col-lg-1 col-sm-12 col-12">
                            <a style="width: 100%;" class="btn btn-success" title="Thêm mới" (click)="DonHangAdd(0)"><i
                                    class="fa fa-plus"></i></a>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table *ngIf="!NotificationService.IsMobile" mat-table matSort #DonHangSort="matSort"
                            [dataSource]="DonHangService.DataSource" class="table table-striped m-0">
                            <ng-container matColumnDef="STT">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="bg-warning">
                                    No
                                </th>
                                <td mat-cell *matCellDef="let element; index as i;" style="text-align: center;">
                                    <div *ngIf="DonHangPaginator">
                                        {{DonHangPaginator.pageSize *
                                        DonHangPaginator.pageIndex + i + 1}}
                                    </div>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="ID">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>ID
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.ID}}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="NgayGhiNhan">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Date created
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <a style="cursor: pointer;" title="{{element.Name}}" class="link-primary"
                                        (click)="DonHangAdd(element.ID)"><b>{{element.NgayGhiNhan
                                            | date:'dd/MM/yyyy'}}</b></a>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="Name">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Name
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <a style="cursor: pointer;" title="{{element.Name}}" class="link-primary"
                                        (click)="DonHangAdd(element.ID)"><b>{{element.Name}}</b></a>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="NguoiMuaName">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Buyer
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <a style="cursor: pointer;" title="{{element.NguoiMuaName}}" class="link-primary"
                                        (click)="ThanhVienAdd(element.NguoiMuaID)"><b>{{element.NguoiMuaName}}</b></a>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="Active">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Active
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <div class="row gx-4">
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <div class="checkbox checkbox-success">
                                                <input type="checkbox" id="Active{{element.ID}}"
                                                    [(ngModel)]="element.Active" />
                                                <label for="Active{{element.ID}}"> Active </label>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="IsThanhToan">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Payment
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <div class="checkbox checkbox-success">
                                        <input type="checkbox" id="IsThanhToan{{element.ID}}"
                                            [(ngModel)]="element.Active" />
                                        <label for="IsThanhToan{{element.ID}}"> Payment </label>
                                    </div>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="IsHoanThanh">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Completed
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <div class="checkbox checkbox-success">
                                        <input type="checkbox" id="IsHoanThanh{{element.ID}}"
                                            [(ngModel)]="element.Active" />
                                        <label for="IsHoanThanh{{element.ID}}"> Completed </label>
                                    </div>
                                </td>
                            </ng-container>                          
                            <tr mat-header-row *matHeaderRowDef="DonHangService.DisplayColumns002">
                            </tr>
                            <tr mat-row *matRowDef="let row; columns: DonHangService.DisplayColumns002;">
                            </tr>
                        </table>
                        <mat-paginator *ngIf="!NotificationService.IsMobile" #DonHangPaginator="matPaginator"
                            [pageSizeOptions]="[10,20,50,100]" [pageSize]="6" [showFirstLastButtons]></mat-paginator>
                        <table *ngIf="NotificationService.IsMobile" mat-table matSort #DonHangSort="matSort"
                            [dataSource]="DonHangService.DataSource" class="table table-striped m-0">
                            <ng-container matColumnDef="STT">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    List
                                </th>
                                <td mat-cell *matCellDef="let element; index as i;">
                                    <div class="row gx-4">
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label>Tiêu đề</label>
                                            <a style="cursor: pointer;" title="{{element.Name}}" class="link-primary"
                                                (click)="DonHangAdd(element.ID)"><b>{{element.Name}}</b></a>
                                        </div>                                       
                                    </div>
                                </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="DonHangService.DisplayColumnsMobile">
                            </tr>
                            <tr mat-row *matRowDef="let row; columns: DonHangService.DisplayColumnsMobile;">
                            </tr>
                        </table>
                        <mat-paginator *ngIf="NotificationService.IsMobile" #DonHangPaginator="matPaginator"
                            [pageSizeOptions]="[10,20,50,100]" [pageSize]="10000"
                            [showFirstLastButtons]></mat-paginator>



                    </div>
                </div>
            </div>
        </div>
    </div>
</section>