import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';

import { DonHang } from 'src/app/shared/DonHang.model';
import { DonHangService } from 'src/app/shared/DonHang.service';
import { DonHangChiTiet } from 'src/app/shared/DonHangChiTiet.model';
import { DonHangChiTietService } from 'src/app/shared/DonHangChiTiet.service';
import { DonHangTapTinDinhKem } from 'src/app/shared/DonHangTapTinDinhKem.model';
import { DonHangTapTinDinhKemService } from 'src/app/shared/DonHangTapTinDinhKem.service';
import { DonHangThanhToan } from 'src/app/shared/DonHangThanhToan.model';
import { DonHangThanhToanService } from 'src/app/shared/DonHangThanhToan.service';

import { ThanhVien } from 'src/app/shared/ThanhVien.model';
import { ThanhVienService } from 'src/app/shared/ThanhVien.service';

import { DanhMucDichVu } from 'src/app/shared/DanhMucDichVu.model';
import { DanhMucDichVuService } from 'src/app/shared/DanhMucDichVu.service';

import { ThanhVienBlock } from 'src/app/shared/ThanhVienBlock.model';
import { ThanhVienBlockService } from 'src/app/shared/ThanhVienBlock.service';
import { ThanhVienBlockDetailComponent } from '../thanh-vien-block-detail/thanh-vien-block-detail.component';

@Component({
  selector: 'app-don-hang-detail',
  templateUrl: './don-hang-detail.component.html',
  styleUrls: ['./don-hang-detail.component.css']
})
export class DonHangDetailComponent implements OnInit {

  @ViewChild('DonHangChiTietSort') DonHangChiTietSort: MatSort;
  @ViewChild('DonHangChiTietPaginator') DonHangChiTietPaginator: MatPaginator;
  @ViewChild('DonHangTapTinDinhKemSort') DonHangTapTinDinhKemSort: MatSort;
  @ViewChild('DonHangTapTinDinhKemPaginator') DonHangTapTinDinhKemPaginator: MatPaginator;
  @ViewChild('DonHangThanhToanSort') DonHangThanhToanSort: MatSort;
  @ViewChild('DonHangThanhToanPaginator') DonHangThanhToanPaginator: MatPaginator;

  @ViewChild('ThanhVienBlockSort') ThanhVienBlockSort: MatSort;
  @ViewChild('ThanhVienBlockPaginator') ThanhVienBlockPaginator: MatPaginator;

  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<DonHangDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

    public NotificationService: NotificationService,
    public DownloadService: DownloadService,

    public DonHangService: DonHangService,
    public DonHangChiTietService: DonHangChiTietService,
    public DonHangTapTinDinhKemService: DonHangTapTinDinhKemService,
    public DonHangThanhToanService: DonHangThanhToanService,

    public ThanhVienService: ThanhVienService,

    public DanhMucDichVuService: DanhMucDichVuService,

    public ThanhVienBlockService: ThanhVienBlockService,

  ) { }

  ngOnInit(): void {
    this.ThanhVienService.GetLogin();
    this.ThanhVienSearch();
    this.DanhMucDichVuSearch();
    this.DonHangSearch();
  }

  ThanhVienBlockSearch() {
    this.DonHangService.IsShowLoading = true;
    this.ThanhVienBlockService.BaseParameter.DonHangID = this.DonHangService.FormData.ID;
    this.ThanhVienBlockService.GetByDonHangIDToListAsync().subscribe(
      res => {
        this.ThanhVienBlockService.List = (res as ThanhVienBlock[]).sort((a, b) => (a.NgayGhiNhan < b.NgayGhiNhan ? 1 : -1));
        this.ThanhVienBlockService.DataSource = new MatTableDataSource(this.ThanhVienBlockService.List);
        this.ThanhVienBlockService.DataSource.sort = this.ThanhVienBlockSort;
        this.ThanhVienBlockService.DataSource.paginator = this.ThanhVienBlockPaginator;
        this.DonHangService.IsShowLoading = false;
      },
      err => {
        this.DonHangService.IsShowLoading = false;
      }
    );
  }
  ThanhVienBlockAdd(ID: number) {
    this.ThanhVienBlockService.BaseParameter.ID = ID;
    this.ThanhVienBlockService.GetByIDAsync().subscribe(
      res => {
        this.ThanhVienBlockService.FormData = res as ThanhVienBlock;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(ThanhVienBlockDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
        });
      },
      err => {
      }
    );
  }
  DanhMucDichVuSearch() {
    this.DanhMucDichVuService.ComponentGetAllToListAsync(this.DonHangService);
  }
  ThanhVienSearch() {
    this.ThanhVienService.ComponentGetAllToListAsync(this.DonHangService);
  }
  ThanhVienFilter(searchString: string) {
    this.ThanhVienService.Filter(searchString);
  }
  DateNgayGhiNhan(value) {
    this.DonHangService.FormData.NgayGhiNhan = new Date(value);
  }
  DonHangThanhToanDateNgayGhiNhan(value, element: DonHangThanhToan) {
    element.NgayGhiNhan = new Date(value);
  }
  NguoiMuaChange() {
    let ListThanhVien = this.ThanhVienService.ListFilter.filter((item: any) =>
      item.ID == this.DonHangService.FormData.NguoiMuaID);
    if (ListThanhVien) {
      if (ListThanhVien.length > 0) {
        let ThanhVien = ListThanhVien[0];
        this.DonHangService.FormData.NguoiMuaName = ThanhVien.Name;
        this.DonHangService.FormData.NguoiMuaDiaChiVi = ThanhVien.DiaChiVi;
      }
    }
  }
  DanhMucDichVuChange(element: DonHangChiTiet) {
    let ListDanhMucDichVu = this.DanhMucDichVuService.ListFilter.filter((item: any) =>
      item.ID == element.DanhMucDichVuID);
    if (ListDanhMucDichVu) {
      if (ListDanhMucDichVu.length > 0) {
        let DanhMucDichVu = ListDanhMucDichVu[0];
        element.SoLuongTransation = DanhMucDichVu.SoLuongTransation;
        element.SoLuongGas = DanhMucDichVu.SoLuongGas;
        element.SoLuongThang = DanhMucDichVu.SoLuongThang;
        element.DonGia = DanhMucDichVu.GiaTri;
        element.SoLuong = 1;
      }
    }
  }
  DonHangSearch() {
    this.DonHangService.IsShowLoading = true;
    this.DonHangService.BaseParameter.ID = this.DonHangService.FormData.ID;
    this.DonHangService.GetByIDAsync().subscribe(
      res => {
        this.DonHangService.FormData = res as DonHang;
        this.DonHangService.FormData.NguoiMuaID = this.ThanhVienService.FormDataLogin.ID;
        this.NguoiMuaChange();
        this.DonHangLoad();
        this.DonHangService.IsShowLoading = false;
      },
      err => {
        this.DonHangService.IsShowLoading = false;
      }
    );
  }
  DonHangLoad() {
    this.DonHangChiTietSearch();
    this.DonHangThanhToanSearch();
    this.DonHangTapTinDinhKemSearch();
    this.ThanhVienBlockSearch();
  }
  DonHangSync() {
    if (this.DonHangService.FormData.ID > 0) {
      this.DonHangSearch();
    }
    else {
      this.DonHangLoad();
    }
  }
  DonHangChiTietSearch() {
    this.DonHangService.IsShowLoading = true;
    this.DonHangChiTietService.BaseParameter.SearchString = this.DonHangService.FormData.Code;
    this.DonHangChiTietService.GetBySearchStringAndEmptyToListAsync().subscribe(
      res => {
        this.DonHangChiTietService.List = (res as DonHangChiTiet[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.DonHangChiTietService.DataSource = new MatTableDataSource(this.DonHangChiTietService.List);
        this.DonHangChiTietService.DataSource.sort = this.DonHangChiTietSort;
        this.DonHangChiTietService.DataSource.paginator = this.DonHangChiTietPaginator;
        this.DonHangService.IsShowLoading = false;
      },
      err => {
        this.DonHangService.IsShowLoading = false;
      }
    );
  }
  DonHangChiTietSave(element: DonHangChiTiet) {
    this.DonHangService.IsShowLoading = true;
    element.ParentID = this.DonHangService.FormData.ID;
    element.Code = this.DonHangService.FormData.Code;
    this.DonHangChiTietService.FormData = element;
    this.DonHangChiTietService.SaveAsync().subscribe(
      res => {
        this.DonHangSync();
        this.NotificationService.warn(environment.SaveSuccess);
        this.DonHangService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.DonHangService.IsShowLoading = false;
      }
    );
  }
  DonHangChiTietDelete(element: DonHangChiTiet) {
    if (confirm(environment.DeleteConfirm)) {
      this.DonHangService.IsShowLoading = true;
      this.DonHangChiTietService.BaseParameter.ID = element.ID;
      this.DonHangChiTietService.RemoveAsync().subscribe(
        res => {
          this.DonHangSync();
          this.NotificationService.warn(environment.SaveSuccess);
          this.DonHangService.IsShowLoading = false;
        },
        err => {
          this.NotificationService.warn(environment.SaveNotSuccess);
          this.DonHangService.IsShowLoading = false;
        }
      );
    }
  }

  DonHangThanhToanSearch() {
    this.DonHangService.IsShowLoading = true;
    this.DonHangThanhToanService.BaseParameter.SearchString = this.DonHangService.FormData.Code;
    this.DonHangThanhToanService.GetBySearchStringAndEmptyToListAsync().subscribe(
      res => {
        this.DonHangThanhToanService.List = (res as DonHangThanhToan[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.DonHangThanhToanService.DataSource = new MatTableDataSource(this.DonHangThanhToanService.List);
        this.DonHangThanhToanService.DataSource.sort = this.DonHangThanhToanSort;
        this.DonHangThanhToanService.DataSource.paginator = this.DonHangThanhToanPaginator;
        this.DonHangService.IsShowLoading = false;
      },
      err => {
        this.DonHangService.IsShowLoading = false;
      }
    );
  }
  DonHangThanhToanSave(element: DonHangThanhToan) {
    this.DonHangService.IsShowLoading = true;
    element.ParentID = this.DonHangService.FormData.ID;
    element.Code = this.DonHangService.FormData.Code;
    this.DonHangThanhToanService.FormData = element;
    this.DonHangThanhToanService.SaveAsync().subscribe(
      res => {
        this.DonHangSync();
        this.NotificationService.warn(environment.SaveSuccess);
        this.DonHangService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.DonHangService.IsShowLoading = false;
      }
    );
  }
  DonHangThanhToanDelete(element: DonHangThanhToan) {
    if (confirm(environment.DeleteConfirm)) {
      this.DonHangService.IsShowLoading = true;
      this.DonHangThanhToanService.BaseParameter.ID = element.ID;
      this.DonHangThanhToanService.RemoveAsync().subscribe(
        res => {
          this.DonHangSync();
          this.NotificationService.warn(environment.SaveSuccess);
          this.DonHangService.IsShowLoading = false;
        },
        err => {
          this.NotificationService.warn(environment.SaveNotSuccess);
          this.DonHangService.IsShowLoading = false;
        }
      );
    }
  }

  DonHangTapTinDinhKemSearch() {
    this.DonHangService.IsShowLoading = true;
    this.DonHangTapTinDinhKemService.BaseParameter.SearchString = this.DonHangService.FormData.Code;
    this.DonHangTapTinDinhKemService.GetBySearchStringAndEmptyToListAsync().subscribe(
      res => {
        this.DonHangTapTinDinhKemService.List = (res as DonHangTapTinDinhKem[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.DonHangTapTinDinhKemService.DataSource = new MatTableDataSource(this.DonHangTapTinDinhKemService.List);
        this.DonHangTapTinDinhKemService.DataSource.sort = this.DonHangTapTinDinhKemSort;
        this.DonHangTapTinDinhKemService.DataSource.paginator = this.DonHangTapTinDinhKemPaginator;
        this.DonHangService.IsShowLoading = false;
      },
      err => {
        this.DonHangService.IsShowLoading = false;
      }
    );
  }
  DonHangTapTinDinhKemSave(element: DonHangTapTinDinhKem) {
    this.DonHangService.IsShowLoading = true;
    element.ParentID = this.DonHangService.FormData.ID;
    element.Code = this.DonHangService.FormData.Code;
    this.DonHangTapTinDinhKemService.FormData = element;
    this.DonHangTapTinDinhKemService.SaveAndUploadFileAsync().subscribe(
      res => {
        this.DonHangSync();
        this.NotificationService.warn(environment.SaveSuccess);
        this.DonHangService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.DonHangService.IsShowLoading = false;
      }
    );
  }
  DonHangTapTinDinhKemDelete(element: DonHangTapTinDinhKem) {
    if (confirm(environment.DeleteConfirm)) {
      this.DonHangService.IsShowLoading = true;
      this.DonHangTapTinDinhKemService.BaseParameter.ID = element.ID;
      this.DonHangTapTinDinhKemService.RemoveAsync().subscribe(
        res => {
          this.DonHangSync();
          this.NotificationService.warn(environment.SaveSuccess);
          this.DonHangService.IsShowLoading = false;
        },
        err => {
          this.NotificationService.warn(environment.SaveNotSuccess);
          this.DonHangService.IsShowLoading = false;
        }
      );
    }
  }

  DonHangSave() {
    this.DonHangService.IsShowLoading = true;
    this.DonHangService.SaveAsync().subscribe(
      res => {
        this.DonHangService.FormData = res as DonHang;
        this.NotificationService.warn(environment.SaveSuccess);
        this.DonHangService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.DonHangService.IsShowLoading = false;
      }
    );
  }

  DonHangTapTinDinhKemChangeFileName(files: FileList) {
    if (files) {
      this.DonHangTapTinDinhKemService.FileToUpload = files;
    }
  }
  Close() {
    this.dialogRef.close();
  }
}